<template>
    <div class="bg" style="width : 100%;height : 100%;overflow : hidden;position : fixed;left : 0;right : 0;bottom : 0;top : 45px;">
        
    </div>
</template>

<script type="text/javascript">
    export default {
        name : "my-index"
    };
</script>

<style lang="scss" scoped="">
    .bg{
        background-image: url('../assets/img/area.jpg');
        background-position: -20% 10%;
        background-size: 100%;
        background-repeat: repeat-y;
    }
</style>